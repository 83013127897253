import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from '../main'
import  Navigator from './index'
import ArticalForRouter from './Artical';


const blogRouter=({ownerUid, bCode, BlogTitle, dir, withRouter})=>{

    // jsx=<Main setComponent={setComponent} owner={owner} blogData={BlogData} BlogTitle={BlogTitle} setPost={setPostData} dir={dir}  rssUrl={rssUrl}/>
const main=()=>{
    return(<Navigator ownerUid={ownerUid} bCode={bCode} BlogTitle={BlogTitle} dir={dir} withRouter={withRouter}/>)
}

const post =(props)=>{
    // alert("post")
    const postData=props.location.state?props.location.state.postData:null
    const system=props.location.state?props.location.state.System:null
    console.log("router>props:  ",props)
    return(
    // <div>this is my post</div>)
    <ArticalForRouter match={props.match} ownerUid={ownerUid} bCode={bCode} dir={dir} postData={postData} system={system}/>)
}


// const getPostData=()=>{

// }
    return (
        <Router>
          <Route path={withRouter.RelativePath} exact component={main}/>
           <Route path={withRouter.RelativePath+"/post/:id"} component={post} />
         {/* <Route path="/users/:id/:userName" component={UserPage} />
          <Route path="/Documentation/:room" component={ChatDocumentation} />
          <Route path="/JoinByRoom/:room" component={JoinByRoom} /> */}
        </Router>
      );
}


export default blogRouter