import React, {useState, useEffect}from 'react'
import Main from './main'
import Addpost  from './Addpost'
import Artical from './Artical'

const Navigator=({ownerUid, bCode, BlogTitle, dir, withRouter})=>{
  if(!bCode){bCode=ownerUid}
  const rssUrl=`https://dugoly.com/api/blog-single/rss/xml/${ownerUid}/${bCode}`
// ------------------זיהוי משתמש
    const userUid=getCookie("uid")
const owner=(ownerUid===userUid)


// -----------------'קריאת הנתונים'
const [BlogData,setBlogData]=useState({})
const [System, setSystem]=useState({})
const domain="dugoly.com"
const updateData=()=>{
        fetch("https://dugoly.com/api/getAllPosts", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({    
          domain, uid:ownerUid, BCode:bCode
        })
      }).then(response => response.json())
        .then(data => {
      setSystem(data.system)
      delete data.system
        setBlogData(data)
            })}
      useEffect(updateData,[])
      

    const [Component,setComponent]=useState()
    const [PostData, setPostData]=useState()
   let jsx 
switch(Component){
    
    case("artical"):
    jsx=<Artical setComponent={setComponent} owner={owner} postData={PostData} dir={dir} rssUrl={rssUrl} system={System}/>
    break

    case("Addpost"):
    jsx=<Addpost setComponent={setComponent} owner={owner} ownerUid={ownerUid} bCode={bCode} dir={dir} updateData={updateData}/>
    break

    case("editpost"):
    jsx=<Addpost setComponent={setComponent} owner={owner} ownerUid={ownerUid} bCode={bCode} postData={PostData} dir={dir} updateData={updateData}/>
    break

    default:
    jsx=<Main setComponent={setComponent} owner={owner} blogData={BlogData} BlogTitle={BlogTitle} setPost={setPostData} dir={dir}  rssUrl={rssUrl} withRouter={withRouter} System={System}/>


}

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('dugoly_post_id');
if(myParam){
  window.location.href=(window.location.href.split('?')[0]+`/post/${myParam}`)
  // jsx=<Artical setComponent={setComponent} owner={owner} postData={{...BlogData[myParam],postId:myParam}} rssUrl={rssUrl} system={System}/>
   
}

    return(<div>{jsx}</div>

    )


}
export default Navigator

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }