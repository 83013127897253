import React from 'react'
import BlogNavigator from './components/blog/index'
import BlogRouter from './components/blog/forRouter/blogRouter'

export default  ({ ownerUid, bCode, BlogTitle, dir="ltr", withRouter=false}) => {
  return <div> {withRouter?<BlogRouter ownerUid={ownerUid} bCode={bCode} BlogTitle={BlogTitle} dir={dir} withRouter={withRouter}/>: <BlogNavigator ownerUid={ownerUid} bCode={bCode} BlogTitle={BlogTitle} dir={dir}/>} </div>
  
 
}

